<template>
    <div>
        <other-header></other-header>
        <div class="train-detail">
            <detail-header last-name="返回" :title="info.TitleName"/>
            <div class="card">
                <div class="title">{{info.TitleName}}</div>
                <br>
                <div class="time">发布日期：{{info.timeText}}</div>
                <div class="time">浏览：{{info.ViewTimes}}</div>
                <br>
                <div v-html="info.Details"></div>
            </div>
            <div v-if="info.VideoUrl" class="video">
                <video :src="info.videoUrl" controls>
                </video>
            </div>
        </div>
    </div>
</template>

<script>
// train-detail
import DetailHeader from "@/components/detail-header";
import OtherHeader from "@/components/other-header";
export default {
    name: "train-detail",
    components: {OtherHeader, DetailHeader},
    data() {
        return {
            info: {}
        }
    },
    computed: {
        id() {
            return this.$route.query.id;
        }
    },
    activated() {
        this.loadData();
    },
    mounted() {
        this.loadData();
    },
    methods: {
        async loadData() {
            // path /BaseInstitutions/GetInstitutionsInfo
            const params = {
                id: this.id
            };
            const res = await $app.httpGet({
                path: `/BaseTrain/GetTrainInfo`,
                params: params,
            });
            if (res.ok) {
                const data = res.data;
                data.timeText = $app.dateFormatChinese(data.BaseCreateTime);
                data.videoUrl = `${$app.staticHost}${data.VideoUrl}`;
                console.log(data)
                this.info = data;
            }
        },
    }
}
</script>

<style scoped lang="less">
.train-detail {
    width: calc(22rem);
    margin: 0.4rem auto;
    & .title {
        font-size: 0.8rem;
        text-align: center;
        color: #4eabe8;
        font-weight: 600;
        font-family: "Songti SC";
    }
    & .card {
        background-color: #Fff;
        padding: 0.4rem;
        border-radius: 0.12rem;
        text-align: left;
        & .title {
            //font-size: 0.4rem;
        }
        & .time {
            font-size: .28rem;
            color: #999999;
            line-height: .60rem;
        }
    }
    & .video {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0.4rem 0;
        background: #fff;
        margin-top: 0.4rem;
        & video {
            width: 60%;
        }
    }
}
</style>
